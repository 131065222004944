import { addMessage, deleteParticipant, deleteOneMessageFromState } from '../../helper/messageHelper';
import stripHexPrefix from 'strip-hex-prefix';
import crypto from 'crypto';
import eccrypto from 'eccrypto'
import router from '../../router'
import store from "../index"

const getDefaultState = () => {
    return {
        messagesWaitingForConfirmation: [],
        messages: {}
    }
}

const state = getDefaultState();

const mutations = {
    removeMessage(state, delData) {
        deleteOneMessageFromState(state.messages, delData);
    },
    addMessageWaitingForConfirmation(state, messageWithHash) {
        state.messagesWaitingForConfirmation.push(messageWithHash)
    },
    removeWatingMessageByHash(state, hash) {
        state.messagesWaitingForConfirmation = state.messagesWaitingForConfirmation.filter(messageWithHash => {
            return messageWithHash.hashedEncPayloadStringHex !== hash;
        });
    },
    addNewMessage(state, message) {
        if (state.messages == null) {
            state.messages = {}
        }
        addMessage(store.getters.communicationPubKey, state.messages, message) //imported from messageDataStructure, state is given by reference
    },
    removeReceiverByPubKey(state, receiverPubKey) {
        deleteParticipant(state.messages, receiverPubKey)
    },
    loadMessagesFromServer(state, messages) {
        state.messages = messages;
    },
    //called by logout Action in authModul
    clearMessages(state) {
        Object.assign(state, getDefaultState())
    }
}

const actions = {
    SOCKET_ACTION_deleteOneMessage({commit}, delData) {
        commit('removeMessage', delData)
    },
    messageWaitingForConfirmation({ commit }, messageWithHash) {
        commit('addMessageWaitingForConfirmation', messageWithHash)
    },
    SOCKET_ACTION_confirmMessageByHash({ commit }, acceptedData) {
        let message = state.messagesWaitingForConfirmation.find(messagesWithHash => {
            return messagesWithHash.hashedEncPayloadStringHex === acceptedData.hash;
        })
        message = { ...message, saveDate: acceptedData.saveDate, _id: acceptedData._id }

        store.dispatch('SOCKET_ACTION_newMessage', message)
        commit('removeWatingMessageByHash', acceptedData.hash)
    },
    SOCKET_ACTION_discardMessageByHash({ commit }, unAcceptedData) {
        let message = state.messagesWaitingForConfirmation.find(messagesWithHash => {
            return messagesWithHash.hashedEncPayloadStringHex === unAcceptedData.hash;
        })
        message = { ...message, errorDate: unAcceptedData.errorDate }
        store.dispatch('SOCKET_ACTION_newMessage', message)

        commit('removeWatingMessageByHash', unAcceptedData.hash)
    },
    SOCKET_ACTION_deleteReceiver({ commit }, receiverPubKey) {
        if (Array.isArray(receiverPubKey)) {
            const receiverPubKeys = receiverPubKey
            receiverPubKeys.forEach(rPubKey => {
                commit('removeReceiverByPubKey', rPubKey)
            });
        } else {
            commit('removeReceiverByPubKey', receiverPubKey)
            router.replace('/showContacts').catch(() => { });
        }
    },
    SOCKET_ACTION_newMessage({ commit }, message) {

        const bufferedPubKeySender = Buffer.from(stripHexPrefix(message.senderPubKey), 'hex');
        const encPayload = { encText: message.encText, encImage: message.encImage, encFile: message.encFile }
        const encPayloadString = JSON.stringify(encPayload)
        let hashedEncPayloadString = crypto.createHash("sha256").update(encPayloadString, 'utf8').digest();

        if (typeof message.signedEncPayloadHash === 'string') {            
            //messages from server use hex format
            message.signedEncPayloadHash = Buffer.from(stripHexPrefix(message.signedEncPayloadHash), 'hex');
        } 

        // Verify if Sender has signed Message
        eccrypto.verify(bufferedPubKeySender, hashedEncPayloadString, new Uint8Array(message.signedEncPayloadHash)).then(function () {
            commit('addNewMessage', message);
        }).catch(function () {
            console.log("Signature is BAD");
        });
    },
    SOCKET_ACTION_loadMessagesFromServer({ commit }, messages) { // _ ignore first parameter

        commit("clearMessages");

        messages.forEach(message => {
            store.dispatch('SOCKET_ACTION_newMessage', message);
        });


    }
}

const getters = {
    messages(state) {
        return state.messages
    },
    messagesWaitingForConfirmation(state) {
        return state.messagesWaitingForConfirmation
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}