<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app bottom>
      <v-list nav dense>
        <v-list-item-group v-model="group">
          <v-list-item @click="home" v-if="!isAuth">
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <v-list-item @click="myContacts" v-if="isAuth">
            <v-list-item-title>My Contacts</v-list-item-title>
          </v-list-item>

          <v-list-item @click="myProfile" v-if="isAuth">
            <v-list-item-title>My Profile</v-list-item-title>
          </v-list-item>

          <v-list-item @click="news">
            <v-list-item-title>News</v-list-item-title>
          </v-list-item>

          <v-list-item @click="FAQ">
            <v-list-item-title>FAQ</v-list-item-title>
          </v-list-item>

          <v-list-item @click="dataPrivacyStatement">
            <v-list-item-title>Privacy</v-list-item-title>
          </v-list-item>

          <v-list-item @click="termsOfUse">
            <v-list-item-title>Terms of use</v-list-item-title>
          </v-list-item>

          <v-list-item @click="legalNotice">
            <v-list-item-title>Legal notice</v-list-item-title>
          </v-list-item>

          <v-list-item @click="logout" v-if="isAuth">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title
        >WBC-Messenger <v-chip disabled>v. 03 beta</v-chip>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-menu
        left
        bottom
        v-if="isAuth && (isShowMessages() || isShowContacts())"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item>
            <v-list-item-title>
              <showContactPubKeyButton
                v-if="isShowMessages()"
              ></showContactPubKeyButton>
              <deleteMessagesButton
                v-if="isShowMessages()"
              ></deleteMessagesButton>
              <deleteEverthingButton
                v-if="isShowContacts()"
              ></deleteEverthingButton>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main>
      <v-alert type="error" color="grey" v-if="!isSocketConnected()"
        >Try to connect...</v-alert
      >
      <router-view v-if="isSocketConnected()"></router-view>
    </v-main>
    <v-footer app> </v-footer>
  </v-app>
</template>

<script>
import DeleteMessagesButton from "./components/deleteMessagesButton";
import DeleteEverthingButton from "./components/deleteEverthingButton";
import ShowContactPubKeyButton from "./components/showContactPubKeyButton";
import { mapGetters } from "vuex";
import Vue from "vue";

import router from "./router";

export default {
  components: {
    deleteMessagesButton: DeleteMessagesButton,
    deleteEverthingButton: DeleteEverthingButton,
    showContactPubKeyButton: ShowContactPubKeyButton,
  },
  metaInfo: {
    title: "Web-Based Crypto Messenger",
    titleTemplate: "WBC-Messenger - %s",
  },
  async beforeCreate() {
    // autologin moved to router
  },
  data: () => ({
    drawer: false,
    group: null,
    couldNotConnect: false,
    noCookieNeeded: [
      "home",
      "legalNotice",
      "termsOfUse",
      "dataPrivacyStatement",
      "news",
    ],
  }),
  watch: {
    group() {
      this.drawer = false;
    },
  },
  computed: {
    ...mapGetters(["connectedToSocket", "isAuth"]),
  },
  created() {
    this.isCookieAccpeted();
  },
  methods: {
    logout() {
      this.$socket.emit("logout");
      this.$store.dispatch("logout");
    },
    home() {
      router.replace("/").catch(() => {});
    },
    news() {
      router.replace("/news").catch(() => {});
    },
    myContacts() {
      router.replace("/showContacts").catch(() => {});
    },
    myProfile() {
      router.replace("/myProfile").catch(() => {});
    },
    FAQ() {
      router.replace("/FAQ").catch(() => {});
    },
    dataPrivacyStatement() {
      router.replace("/dataPrivacyStatement").catch(() => {});
    },
    termsOfUse() {
      router.replace("/termsOfUse").catch(() => {});
    },
    legalNotice() {
      router.replace("/legalNotice").catch(() => {});
    },
    isShowMessages() {
      return this.$router.currentRoute.name === "showMessages";
    },
    isShowContacts() {
      return this.$router.currentRoute.name === "showContacts";
    },
    isConnected() {
      return this.$socket.connected;
    },
    async isCookieAccpeted() {
      //Cookie is set in mixin cookies.js.

      //check if cookie is needed on this side.
      if (!this.noCookieNeeded.includes(this.$router.currentRoute.name)) {
        Vue.axios
          .get(this.$loginAPIHostname + "/isCookieAccpeted", {
            responseType: "json",
          })
          .then(async (res) => {
            if (res.data.acceptedCookie == true) {
              return;
            } else {
              if (this.isConnected()) {
                this.$socket.emit("logout");
              }
              this.$store.dispatch("logout");
              return;
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        // console.log("no cookie needed for this site");
      }
    },
    isSocketConnected() {
      const noSocket = [
        "home",
        "generateKeyPair",
        "login",
        "legalNotice",
        "termsOfUse",
        "dataPrivacyStatement",
        "news",
        "FAQ",
      ];
      if (
        !this.connectedToSocket &&
        !noSocket.includes(this.$router.currentRoute.name) &&
        this.$router.currentRoute.name != null
      ) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style>
.text_top {
  text-decoration: none;
}
</style>
