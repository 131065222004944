import Vue from 'vue'


export default {
data() {
        return {}
},
methods: {
    acceptCookies() {
     return Vue.axios
      .get(this.$loginAPIHostname + "/acceptedCookie", { responseType: 'json' })
      .then( (res) => {

        if(res.data == true) {
          return true
        } else {
          return false
        }
      })
      .catch((e) => {
        console.log(e)
      });
    },
}

}