<template>
  <v-item-group>
    <v-form v-on:submit.prevent="sendMessage">
      <input
        ref="uploader"
        class="d-none"
        type="file"
        accept="*"
        v-on:change="handleImageUpload"
      />

      <v-text-field
        append-outer-icon="mdi-send"
        prepend-icon="mdi-paperclip"
        v-model="text"
        type="text"
        @click:append-outer="sendMessage()"
        @click:prepend="addFile()"
      >
      </v-text-field>

      <v-alert
        type="error"
        v-model="noFile"
        dismissible
        v-if="fileSizeExceeded"
      >
        Your file is bigger than 5 MB. Please choose a smaller File.
      </v-alert>
      <v-alert
        v-model="noFile"
        dismissible
        type="info"
        icon="mdi-camera"
        v-if="compressedImage"
      >
        {{ compressedImage.name }} (compressed from
        {{ (originalFile.size / 1024 / 1024).toFixed(2) }} MB to
        {{ (compressedImage.size / 1024 / 1024).toFixed(2) }} MB)
      </v-alert>

      <v-alert
        v-model="noFile"
        dismissible
        type="info"
        icon="mdi-paperclip"
        v-if="compressedBase64File"
      >
        {{ originalFile.name }}
      </v-alert>
    </v-form>
  </v-item-group>
</template>

<script>
import imageCompression from "browser-image-compression";
import crypto from "crypto";
import aes256 from "aes256";
import eccrypto from "eccrypto";
import { isMimeTypeAllowed, toBase64String } from "../helper/blobHelper";
import deriveSharedSecret from "../mixins/deriveSharedSecret.js";
import JSZip from "jszip";

export default {
  mixins: [deriveSharedSecret],
  data() {
    return {
      text: "",
      noFile: true,
      originalFile: null,
      compressedImage: null,
      fileSizeExceeded: false,
      compressedBase64File: null,
    };
  },
  watch: {
    noFile(val) {
      if (!val) {
        this.$refs.uploader.value = null;
        this.compressedImage = null;
        this.originalFile = null;
        this.compressedBase64File = null;
        this.noFile = true;
        this.fileSizeExceeded = false;
      }
    },
  },
  methods: {
    async handleImageUpload(event) {
      //old firefox
      event.preventDefault();

      this.originalFile = event.target.files[0];

      //options for image compression
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      try {
        if (await isMimeTypeAllowed(this.originalFile)) {
          this.compressedImage = await imageCompression(
            this.originalFile,
            options
          );
        } else {
          //not an image

          //check filesize of non compressed file
          if (this.originalFile.size / 1024 / 1024 > 5) {
            this.fileSizeExceeded = true;
            return;
          }

          this.fileSizeExceeded = false;

          var zip = new JSZip();
          //read filename from files
          zip.file(this.originalFile.name, this.originalFile);

          this.compressedBase64File = await zip.generateAsync({
            type: "base64",
            compression: "DEFLATE",
            compressionOptions: {
              level: 6,
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    addFile() {
      //open input (not visible, ref is uploader)
      this.compressedImage = null;
      this.originalFile = null;
      this.compressedBase64File = null;
      this.$refs.uploader.value = null;
      this.$refs.uploader.click();
    },
    async sendMessage() {
      if (
        (!this.text && !this.compressedImage && !this.compressedBase64File) ||
        this.fileSizeExceeded == true
      ) {
        return;
      }

      try {
        //use mixin and derive shared secret
        if (await this.deriveSharedSecret(this.receiverPubKey)) {
          //encrypt Message text with aes
          let encText = null;
          if (this.text) {
            encText = await aes256.encrypt(this.sharedSecret, this.text);
          }

          //check if image is set, encode to base64, encrypt image
          let encImage = null;
          let encFile = null;
          if (this.compressedImage) {
            const imageBlob = new Blob([this.compressedImage]);
            encImage = await aes256.encrypt(
              this.sharedSecret,
              await toBase64String(imageBlob)
            );
          } else if (this.compressedBase64File) {
            encFile = await aes256.encrypt(
              this.sharedSecret,
              this.compressedBase64File
            );
          }

          const encPayload = {
            encText: encText,
            encImage: encImage,
            encFile: encFile,
          };
          const encPayloadString = JSON.stringify(encPayload);

          //hash encrypted Payload
          const hashedEncPayloadString = crypto
            .createHash("sha256")
            .update(encPayloadString, "utf8")
            .digest();
          const hashedEncPayloadStringHex = Buffer.from(
            hashedEncPayloadString
          ).toString("hex");

          //sign encrypted text
          const signedEncPayloadHash = await eccrypto
            .sign(this.bufferedCommunicationPrivateKey, hashedEncPayloadString)
            .then(function(sig) {
              return sig;
            })
            .catch(function(err) {
              console.log("Signature Error: " + err);
            });

          let messageData = {
            encText: encText,
            encImage: encImage,
            encFile: encFile,
            signedEncPayloadHash: signedEncPayloadHash,
            senderPubKey: this.communicationPubKey,
            receiverPubKey: this.receiverPubKey,
          };

          let messageDataWithHex = {
            ...messageData,
            hashedEncPayloadStringHex,
          };

          //waiting for Confirmation
          this.$store.dispatch(
            "messageWaitingForConfirmation",
            messageDataWithHex
          );
          this.$socket.emit("send", messageData);

          this.text = "";
          this.compressedImage = null;
          this.originalFile = null;
          this.compressedBase64File = null;
          this.noFile = true;
        } else {
          console.log("Failed to derive shared Secret");
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
  props: ["receiverPubKey"], //hex
};
</script>
