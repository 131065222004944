<template>
  <v-container fluid class="fill-height">
    <v-row class="fill-height" justify="center">
      <v-col
        md="8"
        cols="12"
        v-if="keyLoaded"
        class="fill-height d-flex flex-column"
      >
        <v-card
          ref="chatContainer"
          elevation="5"
          class="chat-container flex-grow-1 flex-shrink-0"
        >
          <v-sheet
            class="message"
            v-for="(message, index) in this.getMessages"
            v-bind:key="message.saveDate"
            :class="{ own: message.senderPubKey == communicationPubKey }"
          >
            <v-sheet class="pubKey" v-if="index == 0"
              >{{ message.senderPubKey.substring(0, 5) }} ...
              {{
                message.senderPubKey.substring(
                  message.senderPubKey.length - 5,
                  message.senderPubKey.length
                )
              }}
              <p class="caption">{{ formatDate(message.saveDate) }}</p>
            </v-sheet>

            <v-sheet
              class="pubKey"
              v-if="
                index > 0 &&
                  getMessages[index - 1].senderPubKey != message.senderPubKey
              "
              >{{ message.senderPubKey.substring(0, 5) }} ...
              {{
                message.senderPubKey.substring(
                  message.senderPubKey.length - 5,
                  message.senderPubKey.length
                )
              }}
              <p class="caption">{{ formatDate(message.saveDate) }}</p></v-sheet
            >

            <v-sheet
              class="content"
              elevation="5"
              :class="{ error: message.errorDate }"
            >
              <decryptImage
                :key="message._id"
                :encImage="message.encImage"
                :shared="sharedSecret"
                v-if="message.encImage"
                @decrypted="decrypted"
              ></decryptImage>
              <decryptFile
                :key="message._id"
                :shared="sharedSecret"
                :encFile="message.encFile"
                v-on:decrypted="decrypted"
                v-if="message.encFile"
              ></decryptFile>
              <decryptMessage
                :key="message._id"
                :shared="sharedSecret"
                :encText="message.encText"
                v-on:decrypted="decrypted"
                v-if="message.encText"
              ></decryptMessage>
              <deleteMessage :_id="message._id"> </deleteMessage>
            </v-sheet>
            <br />
            <v-sheet
              class="content"
              v-if="message.errorDate"
              :class="{ error: message.errorDate }"
            >
              Error: Message could neither saved nor send to receiver. Please
              try again later (you may have exceeded your disk limit on the
              server (100 MB / 24h) or you logged in twice or your message was
              to big).
            </v-sheet>
          </v-sheet>
        </v-card>
        <sendMessage
          :receiverPubKey="receiverPubKey"
          v-if="keyLoaded"
          class="flex-grow-0 flex-shrink-1"
        ></sendMessage>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import deriveSharedSecret from "../mixins/deriveSharedSecret";
import DecryptMessage from "../components/decMessage";
import DecryptImage from "../components/decImage";
import DecryptFile from "../components/decFile";
import DeleteMessage from "../components/deleteOneMessageButton";
import SendMessage from "./sendMessage";
import router from "../router";
import dateFormat from "dateformat";
import { mapGetters } from "vuex";

export default {
  mixins: [deriveSharedSecret],
  components: {
    decryptMessage: DecryptMessage,
    decryptImage: DecryptImage,
    decryptFile: DecryptFile,
    deleteMessage: DeleteMessage,
    sendMessage: SendMessage,
  },
  data() {
    return {
      participantBufferedPubKey: "",
      receiverPubKey: this.$route.params.receiverPubKey,
      keyLoaded: false,
    };
  },
  async created() {
    const secretDerived = await this.deriveSharedSecret(this.receiverPubKey);

    // if shared secret cannot be derived
    if (!secretDerived) {
      router.replace("/").catch(() => {});
    }
    // console.log(this.$store.getters.messages[this.receiverPubKey]);
    this.keyLoaded = true;
  },
  methods: {
    decrypted() {
      //gets Event from Child DecryptMessage and DecryptImage
      this.scrollToEnd();
    },
    convertSignedEncPayloadHashToHex(signedEncPayloadHash) {
      return Buffer.from(signedEncPayloadHash).toString("hex");
    },
    scrollToEnd() {
      this.$nextTick(() => {
        var container = this.$el.querySelector(".chat-container");

        //test!
        if (container) container.scrollTop = container.scrollHeight;
      });
    },
    formatDate(date) {
      return dateFormat(date, "dd/mm/yyyy, h:MM TT");
    },
  },
  watch: {
    getMessages: {
      deep: true,
      immediate: true,
      handler() {
        // this.$forceUpdate();
        this.scrollToEnd();
      },
    },
  },
  computed: {
    getMessages() {
      return this.$store.getters.messages[this.receiverPubKey];
    },
    ...mapGetters(["communicationPubKey"]),
  },
};
</script>

<style>
.chat-container {
  box-sizing: border-box;
  overflow-y: auto;
  height: 100px;
  padding: 10px;
}

.message {
  margin-bottom: 3px;
}

.message.own {
  text-align: right;
}
.message.own .content {
  background-color: lightskyblue;
}

.message.own .error {
  background-color: red;
}

.chat-container .pubKey {
  margin-top: 15px;
  font-size: 18px;
  font-weight: bold;
}
.chat-container .content {
  padding: 7px;
  background-color: lightgreen;
  border-radius: 10px;
  display: inline-block;
  max-width: 90%;
  max-height: auto !important;
  word-wrap: break-word;
  margin-bottom: 2px;
}
</style>
