import Vue from 'vue'

export function getAllParticipants(messageData) {

  let myParticipants = []

  Object.keys(messageData).map((pubKey) => {
    myParticipants.push(pubKey)
  })
  return myParticipants
}

export function getLastMessagesFromParticipants(messageData) {
  let lastMessagesWithParticipants = [];

  const myParticipants = getAllParticipants(messageData);

  myParticipants.forEach(participant => {
    let lastMessage = messageData[participant][messageData[participant].length - 1];
    let image = false;
    let file = false;
    
    if (lastMessage.encImage) {
      image = true;
    }
    if (lastMessage.encFile) {
      file = true;
    }

    lastMessagesWithParticipants.push({
      participant: participant,
      encText: lastMessage.encText,
      image: image,
      file: file,
      saveDate: lastMessage.saveDate
    })
  })

  return lastMessagesWithParticipants;

}

export function deleteOneMessageFromState(messages, delData) {

  const indexOfMessage= messages[delData.receiverPubKey].map(message => message._id).indexOf(delData._id);

    if(messages[delData.receiverPubKey].length > 1){
      Vue.delete(messages[delData.receiverPubKey], indexOfMessage)
    } else {
      // if its the last message, delete whole contact 
      Vue.delete(messages, delData.receiverPubKey)
    }
}


export function setProp(obj, prop, value) {
  if (!obj[prop]) {
    Vue.set(obj, prop, {})
  }
  if (Array.isArray(obj[prop])) {
    obj[prop].push(value)
  } else {
    obj[prop] = [value]
  }
  return
}

//removing Prop
export function deleteParticipant(obj, prop) {
  if (!obj[prop]) {
    return
  } else {
    Vue.delete(obj, prop)
  }
  return
}

export function addMessage(myPubKey, messagesData, message) {

  if (!myPubKey && !messagesData && !message) {
    console.log("Something went wrong!")
    return
  }

  //To recognize participant
  let participant = null;

  if (myPubKey != message.senderPubKey) {
    participant = message.senderPubKey;
  } else {
    participant = message.receiverPubKey
  }

  setProp(messagesData, participant, message)

}
