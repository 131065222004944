<template>
  <v-item-group>
    <v-dialog v-model="dialog" persistent max-width="400">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="error"
          class="mt-2"
          v-bind="attrs"
          v-on="on"
          :disabled="isEmptyMessages()"
        >
          Delete Messages
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline">
          Are you sure?
        </v-card-title>
        <v-card-text
          >All messages of this user will be deleted and the contact information
          (public Key) of this user will not be displayed anymore in MyContacts.
          <p class="font-weight-bold">
            If you still want to write to this contact, please save the public
            key before continuing.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn color="error" text @click="deleteMessges">
            Delete Messages
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-item-group>
</template>

<script>
import eccrypto from "eccrypto";
import crypto from "crypto";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
    };
  },
  async created() {
  },
  computed: {
    ...mapGetters(["bufferedCommunicationPrivateKey"])
  },
  methods: {
    isEmptyMessages() {
      return (
        this.$store.getters.messages[this.$route.params.receiverPubKey] == null
      );
    },
    async deleteMessges() {
      const receiverPubKey = this.$route.params.receiverPubKey;

      const deletePayload = {
        receiverPubKey: receiverPubKey,
        date: Date.now(),
      };
      const deletePayloadString = JSON.stringify(deletePayload);
      const deletePayloadStringHash = crypto
        .createHash("sha256")
        .update(deletePayloadString, "utf8")
        .digest();

      //sign data
      const signedDeletePayloadStringHash = await eccrypto
        .sign(this.bufferedCommunicationPrivateKey, deletePayloadStringHash)
        .then(function(sig) {
          return sig;
        })
        .catch(function(e) {
          console.log(e);
        });

      if (signedDeletePayloadStringHash) {
        const data = { deletePayload, signedDeletePayloadStringHash };
        this.$socket.emit("deleteMessages", data);
      }
      this.dialog = false;
    },
  },
};
</script>
