var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"8","cols":"12"}},[_c('p',[_c('v-btn',{on:{"click":function($event){return _vm.addContact()}}},[_vm._v("Add Contact")])],1)])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"8","cols":"12"}},[_c('p',[_c('v-card',{staticClass:"mx-auto"},[_c('v-toolbar',{attrs:{"dark":""}},[_c('v-toolbar-title',[_vm._v("Your contacts")])],1),_c('v-list',{attrs:{"two-line":""}},[_c('v-list-item-group',[(this.lastMessagesFromContacts.length == 0)?_c('v-list-item',[_vm._v(" You do not have any contacts yet. Please add some contacts. ")]):_vm._e(),_vm._l((this.lastMessagesFromContacts),function(lastMessage){return _c('v-list-item',{key:lastMessage.participant},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-account")])],1),_c('v-list-item-content',[_c('router-link',{attrs:{"to":{
                      name: 'showMessages',
                      params: { receiverPubKey: lastMessage.participant },
                    }}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(
                        lastMessage.participant.substring(0, 10) +
                          '...' +
                          lastMessage.participant.substring(
                            lastMessage.participant.length - 4,
                            lastMessage.participant.length
                          )
                      )}})],1),_c('v-list-item-subtitle',[(lastMessage.image)?_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-file-image")]):_vm._e(),(lastMessage.file)?_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-paperclip")]):_vm._e(),_vm._v(" "+_vm._s(lastMessage.text.substring(0, 10))+" "+_vm._s(lastMessage.text.length > 10 ? " ..." : "")+" ")],1)],1),_c('v-list-item-action',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.formatDate(lastMessage.saveDate))+" ")])],1)})],2)],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }