<template>
  <v-container fluid>
    <v-form>
      <v-row justify="center">
        <v-col md="8" cols="12">
          <p>
            <v-text-field
              label="Your Private Key"
              v-model="communicationPrivateKey"
              type="text"
            />
          </p>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col md="8" cols="12">
        <v-img :src="captchaImg"
        :key="captchaImg"
        :eager="true"
        max-height="50"
        max-width="150"
        contain
        class="float-left"
        ></v-img>

          <v-btn
            icon
            color="green"
            @click="loadCaptcha"
            class="float-left mf-2 mr-2 mt-3"
          >
            <v-icon>mdi-cached</v-icon>
          </v-btn>

          <v-text-field
            label="Enter captcha"
            v-model="captcha"
            type="text"
            class="float-left"
          />
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col md="8" cols="12">
          <p>
            <v-btn @click="login()" :disabled="!validKeyPair">Login</v-btn>
            <v-btn @click="back()" class="ml-2">Back</v-btn>
          </p>
          <p>
            <v-alert type="error" v-if="captchaWarning">Wrong Captcha</v-alert>
            <v-alert type="error" v-if="apiConnectionError || loginFailed">Could not connect to Server. Please try again later.</v-alert>
          </p>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import login from "../mixins/login.js";
import router from "../router";

export default {
  mixins: [login],
  data() {
    return {
    };
  },
  created() {
    this.loadCaptcha();
  },
  methods: {
    back() {
      router.replace("/");
    },
  },
};
</script>
