<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col md="8" cols="12">
        <v-card outlined>
          <v-card-title>Legal notice</v-card-title>
          <v-card-text>
            <p class="text-subtitle-2">Information according to § 5 TMG</p>

            Daniel Pannek<br />
            c/o Block Services<br />
            Stuttgarter Str. 106<br />
            70736 Fellbach<br />
            Germany<br />

            <p class="text-subtitle-2 mt-4">Contact</p>
            Email:
            <a
              href="#"
              class="cryptedmail"
              data-name="info"
              data-domain="wbc-messenger"
              data-tld="com"
              onclick="window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld; return false;"
            ></a>
            <br />
            <span class="protectedNumber" title="+49 1525 9454968"></span>
            <p class="mt-4">
              WBC-Messenger is a private project, so there is no company behind
              it.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  metaInfo: {
    title: "Legal notice",
    meta: [
      {
        name: "description",
        content: "Legal notice for the Web-Based Crypto Messenger",
      },
      {
        name: "robots",
        content: "noindex",
      },
    ],
  },
};
</script>
<style scoped>
.cryptedmail:after {
  content: attr(data-name) "@" attr(data-domain) "." attr(data-tld);
}

span.protectedNumber:before {
  content: "Phone number: " attr(title);
}
</style>
