import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'

import home from '../views/home'
import showMessages from '../views/showMessages'
import addContact from '../views/addContact'
import showContacts from '../views/showContacts'
import login from '../views/login'
import generateKeyPair from '../views/generateKeyPair'
import myProfile from '../views/myProfile'
import FAQ from '../views/FAQ'
import news from '../views/news'
import store from '../store';
import legalNotice from '../views/legalNotice'
import termsOfUse from '../views/termsOfUse'
import dataPrivacyStatement from '../views/dataPrivacyStatement'


Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
  { path: '/login', name: 'login', component: login },
  { path: '/myProfile', name: 'myProfile', component: myProfile },
  { path: '/FAQ', name: 'FAQ', component: FAQ },
  { path: '/news', name: 'news', component: news },
  { path: '/legalNotice', name: 'legalNotice', component: legalNotice },
  { path: '/termsOfUse', name: 'termsOfUse', component: termsOfUse },
  { path: '/dataPrivacyStatement', name: 'dataPrivacyStatement', component: dataPrivacyStatement },
  { path: '/generateKeyPair', name: 'generateKeyPair', component: generateKeyPair },
  { name: 'showMessages', path: '/showMessages/:receiverPubKey', component: showMessages },
  { path: '/addContact', name: 'addContact', component: addContact },
  { path: '/showContacts', name: 'showContacts', component: showContacts },
  { path: '/', name: 'home', component: home }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {

  const withoutAuth = ['home', 'generateKeyPair', 'login', 'legalNotice', 'termsOfUse', 'dataPrivacyStatement', 'news', 'FAQ'];

  if (!withoutAuth.includes(to.name)) {

    if (store.getters.isAuth) {
      next()
    } else {
      const login = await store.dispatch('tryAutoLogin')
      if (login) {
        next()
      } else {
        next('/')
      }
    }

  } else {
    next()
  }

})


export default router