<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col md="8" cols="12">
        <p>
          <v-btn @click="addContact()">Add Contact</v-btn>
        </p>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col md="8" cols="12">
        <p>
          <v-card class="mx-auto">
            <v-toolbar dark>
              <v-toolbar-title>Your contacts</v-toolbar-title>
            </v-toolbar>

            <v-list two-line>
              <v-list-item-group>
                <v-list-item v-if="this.lastMessagesFromContacts.length == 0">
                  You do not have any contacts yet. Please add some contacts.
                </v-list-item>
                <v-list-item
                  v-for="lastMessage in this.lastMessagesFromContacts"
                  :key="lastMessage.participant"
                >
                  <v-list-item-avatar>
                    <v-icon>mdi-account</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <router-link
                      :to="{
                        name: 'showMessages',
                        params: { receiverPubKey: lastMessage.participant },
                      }"
                    >
                      <v-list-item-title
                        v-text="
                          lastMessage.participant.substring(0, 10) +
                            '...' +
                            lastMessage.participant.substring(
                              lastMessage.participant.length - 4,
                              lastMessage.participant.length
                            )
                        "
                      ></v-list-item-title>
                    </router-link>
                    <v-list-item-subtitle>
                      <v-icon color="grey lighten-1" v-if="lastMessage.image"
                        >mdi-file-image</v-icon
                      >
                      <v-icon color="grey lighten-1" v-if="lastMessage.file"
                        >mdi-paperclip</v-icon
                      >
                      {{ lastMessage.text.substring(0, 10) }}
                      {{ lastMessage.text.length > 10 ? " ..." : "" }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action class="caption">
                    {{ formatDate(lastMessage.saveDate) }}
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import deriveSharedSecret from "../mixins/deriveSharedSecret";
import dateFormat from "dateformat";
import aes256 from "aes256";
import router from "../router";
import { getLastMessagesFromParticipants } from "../helper/messageHelper";
import { mapGetters } from "vuex";

export default {
  mixins: [deriveSharedSecret],
  data() {
    return {
      myParticipants: [],
      lastMessagesFromContacts: [],
    };
  },
  async created() {
    this.lastMessagesFromContacts = await this.getLastMessagesFromContacts();
  },
  watch: {
    messages: {
      deep: true,
      immediate: false,
      async handler() {
        this.lastMessagesFromContacts = await this.getLastMessagesFromContacts();
      },
    },
  },
  computed: {
    ...mapGetters(["messages"]),
  },
  methods: {
    formatDate(date) {
      return dateFormat(date, "dd, mm, yyyy, h:MM TT");
    },
    addContact() {
      router.replace("addContact").catch(() => {});
    },
    async getLastMessagesFromContacts() {
      const myParticipantsWithLastMessages = getLastMessagesFromParticipants(
        this.messages
      );

      let lastMessagesFromContacts = await Promise.all(
        myParticipantsWithLastMessages.map(async (lastMessage) => {
          let shared = await this.deriveSharedSecret(lastMessage.participant);
          let text = "";
          if (lastMessage.encText) {
            text = await aes256.decrypt(shared, lastMessage.encText);
          }
          return {
            participant: lastMessage.participant,
            text: text,
            image: lastMessage.image,
            file: lastMessage.file,
            saveDate: lastMessage.saveDate,
          };
        })
      );
      lastMessagesFromContacts.sort(function(a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.saveDate) - new Date(a.saveDate);
      });

      return lastMessagesFromContacts;
    },
  },
};
</script>
