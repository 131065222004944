<template>
  <v-item-group>
    <v-dialog v-model="dialog" persistent max-width="400">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="error"
          v-bind="attrs"
          v-on="on"
          :disabled="isEmptyMessages()"
          x-small
        >
          <v-icon small>mdi-delete</v-icon>
          delete
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline">
          Are you sure?
        </v-card-title>
        <v-card-text
          >Are you sure that you want to delete this message for you and for
          your contact?
          <p class="font-weight-bold">
            If this is the last message in this conversation, you will delete
            the whole contact and lose the public key of this contact.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn color="error" text @click="deleteMessge">
            Delete Message
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-item-group>
</template>

<script>
import eccrypto from "eccrypto";
import crypto from "crypto";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
    };
  },
  props: ["_id"],
  computed: {
    ...mapGetters(["bufferedCommunicationPrivateKey"]),
  },
  methods: {
    isEmptyMessages() {
      return (
        this.$store.getters.messages[this.$route.params.receiverPubKey] == null
      );
    },
    async deleteMessge() {
      const receiverPubKey = this.$route.params.receiverPubKey;

      const deletePayload = {
        receiverPubKey: receiverPubKey,
        _id: this._id,
        date: Date.now(),
      };

      const deletePayloadString = JSON.stringify(deletePayload);
      const deletePayloadStringHash = crypto
        .createHash("sha256")
        .update(deletePayloadString, "utf8")
        .digest();

      //sign data
      const signedDeletePayloadStringHash = await eccrypto
        .sign(this.bufferedCommunicationPrivateKey, deletePayloadStringHash)
        .then(function(sig) {
          return sig;
        })
        .catch(function(e) {
          console.log(e);
        });

      if (signedDeletePayloadStringHash) {
        const delData = { deletePayload, signedDeletePayloadStringHash };
        this.$socket.emit("deleteOneMessage", delData);
      }

      this.dialog = false;
    },
  },
};
</script>
