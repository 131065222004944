<template>
  <v-container fluid>
    <v-form>
      <v-row justify="center">
        <v-col md="8" cols="12">
          <v-expansion-panels class="mb-5">
            <v-expansion-panel>
              <v-expansion-panel-header class="font-weight-bold">
                What's the difference between a public and a private key and why
                do I need them?</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <p>
                  To put it very simply, the public key can be compared to a
                  username on a messenger. This can and must be passed on to
                  other participants for communication purposes.
                </p>
                <p>
                  The private key, on the other hand, can be compared to a
                  password. This key must never be passed on to anyone else and
                  must be kept secret. If you lose your private key, it cannot
                  be recovered or reset as it is not stored on our server.
                </p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-text-field
            label="Your Public Key"
            v-model="communicationPubKey"
            type="text"
          />

          <v-text-field
            label="Your Private Key"
            v-model="communicationPrivateKey"
            type="text"
          />
          <p>
            <v-btn @click="generateKeyPair()">Generate Key Pair</v-btn>
          </p>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col md="8" cols="12">
          <v-img
            :src="captchaImg"
            :key="captchaImg"
            :eager="true"
            max-height="50"
            max-width="150"
            contain
            class="float-left"
          ></v-img>

          <v-btn
            icon
            color="green"
            @click="loadCaptcha"
            class="float-left mf-2 mr-2 mt-3"
          >
            <v-icon>mdi-cached</v-icon>
          </v-btn>

          <v-text-field
            label="Enter captcha"
            v-model="captcha"
            type="text"
            class="float-left"
          />
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col md="8" cols="12">
          <p>
            <v-btn @click="login()" :disabled="!validKeyPair">Login</v-btn>
            <v-btn @click="back()" class="ml-2">Back</v-btn>
          </p>
          <p>
            <v-alert type="error" v-if="captchaWarning">Wrong Captcha</v-alert>
            <v-alert type="error" v-if="apiConnectionError || loginFailed"
              >Could not connect to Server. Please try again later.</v-alert
            >
          </p>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import eccrypto from "eccrypto";
import login from "../mixins/login.js";
import router from "../router";

export default {
  mixins: [login],
  data() {
    return {};
  },
  async created() {
    this.loadCaptcha();
  },
  methods: {
    generateKeyPair() {
      this.bufferedCommunicationPrivateKey = eccrypto.generatePrivate();
      this.bufferedCommunicationPublicKey = eccrypto.getPublic(
        this.bufferedCommunicationPrivateKey
      );

      this.communicationPrivateKey = this.bufferedCommunicationPrivateKey.toString(
        "hex"
      );
      this.communicationPublicKey = this.bufferedCommunicationPublicKey.toString(
        "hex"
      );
    },
    back() {
      router.replace("/");
    },
  },
};
</script>
