import stripHexPrefix from 'strip-hex-prefix';
import router from '../../router'

const getDefaultState = () => {
    return {
        communicationPubKey: '',
        communicationPrivateKey: '',
        bufferedCommunicationPrivateKey: '',
        bufferedCommunicationPubKey: '',
        isAuth: false,
        authToken: '',
        connectedToSocket: false
    }
}

const state = getDefaultState()

const mutations = {
    addKeyPair(state, keyPair) {
        state.communicationPubKey = keyPair.communicationPubKey;
        state.communicationPrivateKey = keyPair.communicationPrivateKey;
        state.bufferedCommunicationPrivateKey = keyPair.bufferedCommunicationPrivateKey;
        state.bufferedCommunicationPubKey = keyPair.bufferedCommunicationPubKey;
        state.isAuth = true;
    },
    addAuthToken(state, authToken) {
        state.authToken = authToken
    },
    logout(state) {
        Object.assign(state, getDefaultState())
    },
    disconnect(state) {
        state.connectedToSocket = false;
    },
    connect(state) {
        state.connectedToSocket = true;
    }
}

const actions = {
    logout({ commit }) {
        localStorage.clear()
        this._vm.$socket.disconnect();
        commit('logout')
        //call in messageModul
        commit('clearMessages', null, { root: true })
        router.replace('/').catch(() => { });
    },
    async tryAutoLogin({ commit }) {

        return new Promise((resolve, reject) => {

            const communicationPubKey = localStorage.getItem('communicationPubKey')
            const communicationPrivateKey = localStorage.getItem('communicationPrivateKey')

            const authToken = JSON.parse(localStorage.getItem('authToken'))

            if (communicationPubKey == null || communicationPrivateKey == null || authToken == null) {
                reject(false)
                return
            }

            const bufferedCommunicationPubKey = Buffer.from(stripHexPrefix(communicationPubKey), 'hex')
            const bufferedCommunicationPrivateKey = Buffer.from(stripHexPrefix(communicationPrivateKey), 'hex')

            const keyPair = {
                communicationPubKey: communicationPubKey,
                communicationPrivateKey: communicationPrivateKey,
                bufferedCommunicationPrivateKey: bufferedCommunicationPrivateKey,
                bufferedCommunicationPubKey: bufferedCommunicationPubKey
            };

            commit('addKeyPair', keyPair)
            commit('addAuthToken', authToken)

            this._vm.$socket.io.opts.auth.token = this.getters.authToken;

            resolve()

        })
            .then(() => {
                if (this._vm.$socket.connected) {
                    return this._vm.$socket.disconnect();
                }
            })
            .then(() => {
                return this._vm.$socket.connect();
            })
            .then(() => true)
            .catch(() => {
                return false;
            })

    },
    keyPair({ commit }, keyPair) {
        localStorage.setItem('communicationPubKey', keyPair.communicationPubKey)
        localStorage.setItem('communicationPrivateKey', keyPair.communicationPrivateKey)

        commit('addKeyPair', keyPair)
    },
    SOCKET_ACTION_authToken({ commit }, authToken) {
        localStorage.setItem('authToken', JSON.stringify(authToken))
        commit('addAuthToken', authToken)
    },
    SOCKET_ACTION_disconnect({ commit }) {
        commit('disconnect');
    },
    SOCKET_ACTION_connect({ commit }) {
        commit('connect');
    }

}

const getters = {
    communicationPubKey(state) {
        return state.communicationPubKey;
    },
    communicationPrivateKey(state) {
        return state.communicationPrivateKey;
    },
    bufferedCommunicationPrivateKey(state) {
        return state.bufferedCommunicationPrivateKey;
    },
    bufferedCommunicationPubKey(state) {
        return state.bufferedCommunicationPubKey;
    },
    isAuth(state) {
        return state.isAuth;
    },
    authToken(state) {
        return state.authToken;
    },
    connectedToSocket(state) {
        return state.connectedToSocket;
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}