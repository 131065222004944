<template>
  <v-container fluid>
     <v-row justify="center">

      <v-col md ="8" cols="12" >
   
      <h1> Welcome to the Web-Based Crypto Messenger without registration </h1>
      <br/>
      <p class="text--primary">The <strong>Web-Based Crypto Messenger</strong> is a secure messenger web app that can be used anonymously and without registration. All encryption and decryption takes place on your device in your browser. The signature and verification of the signature for each message is also done in your browser.</p>
      <p class="text--primary">
      Some facts about the WBC-Messenger:
      </p>
      <p>
      <ul class="text--primary">
        <li>The use of this service is free.</li>
        <li>The WBC-Messenger not only gives you control over the messages you send, but also over all the messages you receive.</li>
        <li>The WBC-Messenger is a responsive web app and can therefore be used via a desktop PC, tablet or smartphone.</li>
        <li>You do not need a valid e-mail to use this service. </li>
        <li>You can use your own generated keypair with this service. </li>
        <li>You even can receive messages which where send to a public key on this service before your frist login.</li>
        <li>Your browser has to accept cookies. Please read our <router-link to="/dataPrivacyStatement">data privacy statement</router-link>. </li> 
      </ul>
      </p>
     
      <v-checkbox
      v-model="acceptedPrivacyPolicy"
      class="font-weight-bold mt-0 pt-0 bt-0"
      > 
          <template slot="label">
         <span class="text--primary"> I have read <router-link to="/dataPrivacyStatement">data privacy statement</router-link> and accept it. </span>
        </template>
      </v-checkbox>
    
      <v-checkbox
      v-model="acceptedTermsOfUse"
      :label="`I agree that the and i accept these conditions.`"
      class="font-weight-bold mt-0 pt-0 bt-0"
      > 
       <template slot="label">
         <span class="text--primary"> I have read <router-link to="/termsOfUse"> terms of use</router-link> and accept these conditions. </span>
        </template>
      </v-checkbox>

      <v-checkbox
      v-model="acceptedCookie"
       class="font-weight-bold mt-0 pt-0 bt-0 "
      > 
       <template slot="label">
         <span class="text--primary">I agree that wbc-messenger.com will store  technical necessary cookies on my device. I also agree that wbc-messenger.com will store my access data in the local storage of my browser. You can find detailed informations in our <router-link to="/dataPrivacyStatement">data privacy statement</router-link>. </span>
        </template>
      
      </v-checkbox>


     
      <p>
      
        <v-btn @click="acceptCookieLogin" class="mr-2 mb-2" :disabled="!acceptedCookie || !acceptedPrivacyPolicy || !acceptedTermsOfUse"> Login </v-btn>
        <v-btn @click="acceptCookieGenerateKeyPair" class="mb-2" :disabled="!acceptedCookie || !acceptedPrivacyPolicy || !acceptedTermsOfUse"> Generate new Key </v-btn>
      </p>
     
      </v-col> 

    </v-row>

    </v-container>
</template>

<style scoped>
h1 {
    font-size: 1.3em;
}
</style>

<script>

import cookies from "../mixins/cookies.js";
import router from "../router";

export default {

 metaInfo: {
  meta: [
        {
          name: "description",
          content:
            "The Web-Based Crypto Messenger is a secure messenger web app that can be used anonymously and without registration. All encryption and decryption takes place on your device in your browser. The signature and verification of the signature for each message is also done in your browser.",
        }
      ]
  },
     
  mixins: [cookies],
  data () {
    return {
      acceptedCookie: false,
      acceptedPrivacyPolicy: false,
      acceptedTermsOfUse: false,
    }
  },
  methods: {
    async acceptCookieLogin() {
      const cookiesAccepted = await this.acceptCookies(); //mixin cookies
      if(cookiesAccepted) {
        router.replace("/login").catch(() => {});
      } 
    },
    async acceptCookieGenerateKeyPair() {
   
      let cookiesAccepted = await this.acceptCookies(); //mixin cookies
      if(cookiesAccepted) {
       router.replace("/generateKeyPair").catch(() => {});
      }
    }
  }
}



</script>
