<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col md="8" cols="12">
        <v-card elevation="5" class="pa-5">
          <v-card-title>Your qr-code (public key)</v-card-title>
          <v-card-text>
            <v-img
              :src="myQrCode"
              v-if="loadImage"
              :key="myQrCode"
              width="150"
              height="150"
            ></v-img>
          </v-card-text>

          <v-card-actions>
            <v-btn text :href="myQrCode" download="myQrCode.png"
              >Download QR-Code</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col md="8" cols="12">
        <v-card elevation="5" class="pa-5">
          <v-card-title>Your public key</v-card-title>

          <v-card-text>
            {{ communicationPubKey }}
          </v-card-text>

          <v-card-actions>
            <v-btn text v-clipboard="communicationPubKey" @click="copyPubKey()"><v-icon v-if="!pubKeyCopied">mdi-content-copy</v-icon><v-icon v-if="pubKeyCopied">mdi-check </v-icon>
              Copy to clipboard
            </v-btn>
          </v-card-actions>
        </v-card>

        <br />

        <p>
          <v-btn @click="showPrivateKey = !showPrivateKey">
            <span v-if="showPrivateKey">Hide private key</span
            ><span v-if="!showPrivateKey">Show private key</span>
          </v-btn>
        </p>

        <span v-if="showPrivateKey">
          <v-card elevation="5" class="pa-5">
            <v-card-title>Your private key</v-card-title>
            <v-card-text>
              {{ communicationPrivateKey }}
            </v-card-text>
            <v-card-actions>
              <v-btn text v-clipboard="communicationPrivateKey" @click="copyPrivateKey()"><v-icon v-if="!privateKeyCopied">mdi-content-copy </v-icon><v-icon v-if="privateKeyCopied">mdi-check </v-icon>
                Copy to clipboard
              </v-btn>
            </v-card-actions>
          </v-card>
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import qr from "qr-image";
import { mapGetters } from "vuex";


export default {
  data() {
    return {
      myQrCode: null,
      loadImage: false,
      showPrivateKey: false,
      pubKeyCopied: false,
      privateKeyCopied: false
    };
  },
  async created() {
    let svg_string = await qr.imageSync(this.communicationPubKey, {
      type: "png",
    });
    let blob = new Blob([svg_string], { type: "image/png" });
    this.myQrCode = URL.createObjectURL(blob);
    this.loadImage = true;
  },
  computed: {
    ...mapGetters(["communicationPubKey", "communicationPrivateKey"]),
  },
  methods: {
    copyPubKey(){
      this.pubKeyCopied = true;
      this.privateKeyCopied = false;
    },
     copyPrivateKey(){
      this.privateKeyCopied = true;
      this.pubKeyCopied = false;
    }
  }
};
</script>
<style>
.cardPadding {
  padding: 10px;
}
</style>
