import eccrypto from 'eccrypto';
import stripHexPrefix from 'strip-hex-prefix';
import Vue from 'vue'
import router from '../router'


export default {

  data() {
    return {
      communicationPubKey: '',
      communicationPrivateKey: '',
      bufferedCommunicationPubKey: '',
      bufferedCommunicationPrivateKey: '',
      validKeyPair: false,
      captcha: '',
      captchaWarning: false,
      loginFailed: false,
      captchaImg: null,
      apiConnectionError: false,
      imageURL: null
    }
  },
  methods: {
    async loadCaptcha() {
      Vue.axios
        .get(this.$loginAPIHostname + "/captcha", { responseType: 'blob' })
        .then(async (res) => {
          this.apiConnectionError = false;

          this.captchaImg = URL.createObjectURL(res.data);

          return true;
        })
        .catch((e) => {
          console.log(e)
          this.apiConnectionError = true;
        });
    },
    async login() {
      this.loginFailed = false;
      this.captchaWarning = false;

      Vue.axios.post(this.$loginAPIHostname, { pubKey: this.communicationPubKey, captcha: this.captcha }).then(async (response) => {

        this.captchaWarning = response.data.captchaWarning;

        if (response.data.captchaWarning == false) {

          const verifyToken = new Uint8Array(response.data.hashedVerifyToken.data);

          const signed = await eccrypto.sign(this.bufferedCommunicationPrivateKey, verifyToken).then(function (sig) {
            return sig;
          }).catch(function (err) {
            console.log("Error while singing... "  + err)
          });
          const data = { senderPubKey: this.bufferedCommunicationPubKey, signedToken: signed }

          Vue.axios.post(this.$loginAPIHostname + '/verifySign', data).then(async (response) => {
            if (response.data.auth == true) {

              this.$store.dispatch('SOCKET_ACTION_authToken', response.data.authToken)
              this.$socket.io.opts.auth.token = this.$store.getters.authToken;

            if (this.$socket.connected) {
              await this.$socket.disconnect();
              }
              await this.$socket.connect();
            }

            const keyPair = {
              communicationPubKey: this.communicationPubKey,
              communicationPrivateKey: this.communicationPrivateKey,
              bufferedCommunicationPrivateKey: this.bufferedCommunicationPrivateKey,
              bufferedCommunicationPubKey: this.bufferedCommunicationPubKey
            };
            this.$store.dispatch('keyPair', keyPair)

            router.replace('/showContacts').catch((e) => { console.log(e) });

          }).catch(err => {
            this.loginFailed = true
            console.log(err)
          })

        }


      }).catch(err => {
        this.loginFailed = true
        console.log(err)
      })

    },
    isSocketConnected() {
      return this.$socket.connected;
    }

  },
  watch: {
    communicationPrivateKey(val) {

      val = val.replace(/\s/g, ''); //remove whitespace
    
      this.communicationPrivateKey = val;

      try {
        //transfrom hex private to buffered privateKey
        this.bufferedCommunicationPrivateKey = Buffer.from(stripHexPrefix(val), 'hex')

        //get buffered pubKey from buffered privateKey
        this.bufferedCommunicationPubKey = eccrypto.getPublic(this.bufferedCommunicationPrivateKey);

        //transfrom buffered pubKey to hex pubkey
        this.communicationPubKey = Buffer.from(this.bufferedCommunicationPubKey).toString('hex');

        this.validKeyPair = true;

      } catch (error) {
        this.validKeyPair = false;
      }

    }
  },
  sockets: {
    async verifyToken(token) {

      const verifyToken = new Uint8Array(token);

      const signed = await eccrypto.sign(this.bufferedCommunicationPrivateKey, verifyToken).then(function (sig) {
        return sig;
      }).catch(function (err) {
        console.log("Error while singing... "  + err)
      });
      const data = { senderPubKey: this.bufferedCommunicationPubKey, signedEncTextHash: signed }

      this.$socket.emit('verify', data)

      const keyPair = {
        communicationPubKey: this.communicationPubKey,
        communicationPrivateKey: this.communicationPrivateKey,
        bufferedCommunicationPrivateKey: this.bufferedCommunicationPrivateKey,
        bufferedCommunicationPubKey: this.bufferedCommunicationPubKey
      };
      this.$store.dispatch('keyPair', keyPair)
    }

  }



}