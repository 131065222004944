<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col md="8" cols="12">
        <v-card outlined>
          <v-card-title>Terms of use</v-card-title>
          <v-card-text class="text--primary">
            <p class="text-subtitle-2">
              Scope of the terms of use
            </p>
            <p>
              These terms of use apply to the online offer of wbc-messenger.com.
            </p>
            <p class="text-subtitle-2">
              Use of wbc-messenger.com
            </p>

            <ul>
              <li class="mb-2">
                The use of wbc-messenger.com for users under the age of eighteen
                (18) is forbidden.
              </li>
              <li class="mb-2">
                The user is aware that wbc-messenger.com is a private test
                project in the beta phase and therefore there is no guarantee
                that wbc-messenger.com will function correctly and/or safely.
              </li>
              <li class="mb-2">
                wbc-messenger.com reserves the right to discontinue the entire
                service at any time without giving reasons.
              </li>
              <li class="mb-2">
                wbc-messenger.com reserves the right to delete the entire
                database and thus all communication data at any time without
                giving reasons or notifications. wbc-messenger.com also reserves
                the right to remove the communication data of one or more users
                completely or partially from the database without giving reasons
                and without informing the concerned user(s). After a possible
                deletion of their communication data, the user has no right to
                have this communication data restored.
              </li>
              <li class="mb-2">
                The user undertakes to wbc-messenger.com not to send any content
                via wbc-messenger.com that violates applicable law. The same
                applies to the sending of external links.
              </li>
              <li class="mb-2">
                The user must refrain from any activity that is likely to impair
                or overload the technical infrastructure or the operation of
                wbc-messenger.com. In particular, the automated use of
                wbc-messenger.com using software or scripts is forbidden.
              </li>
            </ul>
          </v-card-text>

          <v-card-actions>
            <v-btn text @click="back">
              back
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import router from "../router";

export default {
  methods: {
    back() {
      router.replace("/showContacts").catch(() => {});
    },
  },
  metaInfo: {
    title: "Terms of use",
    meta: [
      {
        name: "description",
        content: "Terms of use for the Web-Based Crypto Messenger",
      },
    ],
  },
};
</script>
