import eccrypto from 'eccrypto';
import stripHexPrefix from 'strip-hex-prefix';
import { mapGetters } from 'vuex'

export default {

    data() {
        return {
            sharedSecret: null,
            derivedSharedSecret: false
        }
    },
    methods: {
        async deriveSharedSecret(receiverPubKey) {
            try {
                const bufferedReceiverPubKey = Buffer.from(stripHexPrefix(receiverPubKey), 'hex')
                this.sharedSecret = Buffer.from(await eccrypto.derive(this.bufferedCommunicationPrivateKey, bufferedReceiverPubKey)).toString('hex');
                this.derivedSharedSecret = true;
                return this.sharedSecret;
            } catch (e) {
                console.log(e)
                this.derivedSharedSecret = false;
                return false;
            }
        }
    },
    computed: {
        ...mapGetters(["communicationPubKey", "bufferedCommunicationPrivateKey"])
    }

}