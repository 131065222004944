<template>
  <v-item-group>
    <a :href="decImageURL" :download="'image.' + decImageType">
      <v-img
        :src="decImageURL"
        :key="decImageURL"
        :eager="true"
        v-show="isLoad"
        @load="loadedImage"
      ></v-img>
    </a>
  </v-item-group>
</template>

<script>
import aes256 from "aes256";
import { isMimeTypeAllowed, getMimeTypeFromBlob } from "../helper/blobHelper";


export default {
  data() {
    return {
      decImageURL: null,
      decImageType: null,
      isLoad: false,
    };
  },
  async created() {
    await this.decryptImage();
  },
  methods: {
    loadedImage() {
      this.isLoad = true;
      this.$emit("decrypted");
    },

    async decryptImage() {
      try {

        const decImage = await aes256.decrypt(this.shared, this.encImage);
        const decImageBlob = await (await fetch(decImage)).blob();

        if (await isMimeTypeAllowed(decImageBlob)) {
          this.decImageURL = URL.createObjectURL(decImageBlob);
          this.decImageType = await getMimeTypeFromBlob(decImageBlob);
        } else {
          console.log("No image");
        }
      } catch (e) {
        console.log(e);
      }
      return true;
    },
  },
  props: ["encImage", "shared"],
};
</script>
<style></style>
