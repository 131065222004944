import Vue from 'vue'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify';
import VueSocketIO from 'vue-socket.io';
import socketio from 'socket.io-client';
import router from './router'
import axios from 'axios';
import VueAxios from 'vue-axios';
import Clipboard from 'v-clipboard'
import VueMeta from 'vue-meta'

import '@mdi/font/css/materialdesignicons.css'

Vue.use(VueMeta);
Vue.use(Clipboard);

axios.defaults.withCredentials = true;
Vue.use(VueAxios, axios);

Vue.config.productionTip = false

Vue.prototype.$socketIOHostname = 'https://www.wbc-messenger.com:8080'
Vue.prototype.$loginAPIHostname = 'https://www.wbc-messenger.com:8080/api/login'



let options = {
  autoConnect: false, //--> login.js -->  this.$socket.connect()
  auth: {
    token: store.getters.authToken
  }
}

Vue.use(new VueSocketIO({
  debug: true,
  connection: socketio(Vue.prototype.$socketIOHostname, options),
  vuex: {
    store,
    actionPrefix: 'SOCKET_ACTION_',
    mutationPrefix: 'SOCKET_'
  }
}))

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
