<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col md="8" cols="12">
        <v-card outlined>
          <v-card-title>Data privacy statement</v-card-title>
          <v-card-text class="text--primary">
            <p class="text-subtitle-2">
              General
            </p>
            <p>
              This service was developed based on the data protection principles
              "privacy by design" and "data minimization". All communication
              that can take place via this service is end-to-end encrypted
              (exception: public keys and time stamp), so that it is not
              possible for wbc-messenger.com to read the contents of the
              messages either.
            </p>
            <p class="text-subtitle-2">
              Important information about the functionality of the service
              (especially login)
            </p>

            <p>
              Although it is possible to use this service to generate a
              cryptographic key pair in the browser, with which the service of
              this website can be used, this does not constitute a registration,
              since wbc-messenger.com only saves the messages which were
              encrypted, signed and send with a correct cryptographic key pair
              via this service. The service can therefore also be used with a
              correct cryptographic key pair that was not created via this
              service, since our service only checks if the user is in
              possession of the private key belonging to the used public key.
              This is done by transmitting a random string of characters to the
              user, which only can be signed with the correct private key. If
              the signature is correct, all encrypted messages stored on the
              server for the associated public key are transmitted to the user.
              Only the user can then decrypt these messages with the correct
              private key within the browser.
            </p>
            <p class="font-weight-bold">
              If the user deletes all messages on the server, a login is still
              possible at any time, since the login only checks whether a user
              is in possession of the private key belonging to the public key -
              registration therefore never takes place and thus cannot be
              reversed.
            </p>

            <p class="text-subtitle-2">
              Scope of the processing of personal data
            </p>
            <p>
              In principle, we only process personal data of our users insofar
              as this is necessary to provide a functional website and our
              content and services. The processing of personal data of our users
              takes place regularly only with the consent of the user. An
              exception applies in those cases in which prior consent cannot be
              obtained for practical reasons and the processing of the data is
              permitted by law.
            </p>

            <p class="text-subtitle-2">
              Legal basis for the processing of personal data
            </p>
            <p>
              Insofar as we obtain the consent of the data subject for the
              processing of personal data, Article 6 (1) (a) of the EU General
              Data Protection Regulation (GDPR) serves as the legal basis.
              Insofar as the processing of personal data is necessary to fulfill
              a legal obligation to which wbc-messenger.com is commited, Article
              6 (1) (c) of the EU General Data Protection Regulation (GDPR)
              serves as the legal basis. If the processing is necessary for the
              purposes of the legitimate interest of wbc-messenger.com, Article
              6 (1) (f) of the EU General Data Protection Regulation (GDPR)
              serves as the legal basis for the processing.
            </p>

            <p class="text-subtitle-2">Data deletion and storage duration</p>
            <p>
              The personal data of the data subject will be deleted or blocked
              as soon as the purpose of storage no longer applies. The
              communication data of the data subject that is stored on our
              server to provide the service can be partially or completely
              deleted at any time by the data subject who is in possession of
              the private key. In the event of a deletion, the communication
              data of the communication partner is also lost, so that the
              communication partner no longer has access to the communication
              data as well.
            </p>

            <p class="text-subtitle-2">Right to lodge a complaint</p>
            <p>
              If you are of the opinion that the processing of your personal
              data violates the GDPR you have the right to lodge a complaint
              with the competent supervisory authority, in particular in the
              Member State of your habitual residence, your place of work or the
              place of the alleged infringement. You also have the right to
              lodge a complaint with the federal commissioner for data privacy
              of Germany.
            </p>

            <p class="text-subtitle-2">
              Right to information, correction, blocking, deletion
            </p>
            <p>
              Within the framework of the applicable legal provisions, you have
              the right to free information about the personal data stored about
              you, the origin of this data, the purpose of the data processing
              and the recipient of this data at any time. You also have the
              right to correct, block, restrict processing or delete this data.
              Simply contact us at the email address given in the legal notice
              (impressum).
            </p>

            <p class="text-subtitle-2">Right to data portability</p>
            <p>
              You have the right to receive the personal data concerning you in
              a structured, common and machine-readable format. In exercising
              this right, you also have the right to have the personal data
              relating to you transmitted directly from one processor to
              another, insofar as this is technically feasible.
            </p>

            <p class="text-subtitle-2">
              Right to revoke the declaration of consent under data protection
              law
            </p>
            <p>
              You have the right to withdraw your consent under data protection
              law at any time. If you withdraw your consent, this does not
              affect the lawfulness of the processing based on consent before
              its withdrawal.
            </p>

            <p class="text-subtitle-2">
              Name and address of the data processing controller (referred to as
              the "controller" in the GDPR) on this website is
            </p>

            Daniel Pannek<br />
            c/o Block Services<br />
            Stuttgarter Str. 106<br />
            70736 Fellbach<br />
            Germany<br />

            <a
              href="#"
              class="cryptedmail"
              data-name="info"
              data-domain="wbc-messenger"
              data-tld="com"
              onclick="window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld; return false;"
            ></a>

            <p class="text-subtitle-2 mt-4">
              Data which will be stored, when communicating via this service
            </p>
            <p>
              When logging in with a cryptographic key pair, no data is
              initially stored on the server (except the data in the server's
              log files, see “log files”). If a message is sent, the following
              data is stored on the server for the provision of the service:
            </p>

            <ul class="mb-4">
              <li>the sender's public key</li>
              <li>the recipient's public key</li>
              <li>
                the AES encrypted content of the message (text / image / file)
              </li>
              <li>
                the hash value of the cryptographic signature of the message
              </li>
              <li>the date and time the message reached the server</li>
            </ul>

            <p>
              It should be noted here that a public key public key can not be
              related to any person without this person voluntarily disclosing
              this information on another location (e.g. on the Internet).
            </p>

            <p class="text-subtitle-2">
              Cookies and memory of the browser (localStorage)
            </p>
            <p>
              Cookies are text files that are stored in the internet browser or
              by the internet browser on the user's computer system. When a user
              calls up a website, a cookie can be stored on the user's operating
              system. We use two technically necessary cookies when logging in,
              so that our captcha and our login procedure can work properly.
              These cookies are only set after you have allowed
              wbc-messenger.com to set cookies. The first cookie contains the
              following information in base64 format before you log into the
              system:
            </p>

            <ul class="mb-4">
              <li>content of the captcha</li>
              <li>whether you have accepted cookies</li>
            </ul>

            <p>
              After you have successfully logged in, this cookie also contains a
              hash signed using your private key, which proofs the server that
              you have the correct private key. The second cookie contains a
              signature that proves that you have not manipulated these cookies.
              The cookie we set is valid until the session ends.
            </p>

            <p class="text-subtitle-2">
              After a successful login, the following data is saved in the
              browser's local storage:
            </p>

            <ul class="mb-4">
              <li>public key (Uint8Array and Hex)</li>
              <li>private key (Uint8Array and Hex)</li>
              <li>JSON web token (JWT)</li>
            </ul>

            <p>
              This is necessary for the service to function properly. If the
              "logout" is carried out correctly via the web interface, this data
              is removed again. As long as no “logout” has been carried out,
              this information is used for automatic login. You can of course
              delete this data yourself at any time via your browser.
            </p>

            <p class="text-subtitle-2">
              Log files
            </p>

            <p>
              In order to diagnose errors and prevent misuse as well as for
              statistical evaluation and improvement of our services, our web
              server records the following data in the "log files":
            </p>

            <ul class="mb-4">
              <li>
                anonymized IP address (IPv4 shortend to 16 bit, IPv6 shortend to
                32 Bit)
              </li>
              <li>visited website</li>
              <li>time at the time of access</li>
              <li>amount of data sent in bytes</li>
              <li>source / reference from which you came to the website</li>
              <li>browser used</li>
              <li>operating system used</li>
            </ul>

            <p>
              The IP addresses used are saved anonymized (IPv4 shortend to 16
              bit, IPv6 shortend to 32 Bit). Thus, the data listed above are not
              personal. Furthermore, possible error messages that may arise
              through the use of our service are anonymized (without the user's
              public key) and stored in a separate log file for error diagnosis
              and improvement of our service.
            </p>

            <p class="text-subtitle-2">Google Font</p>

            <p>
              Although we use the Google font (Robots), we do not obtain it from
              the Google CDN, so that only the data mentioned under log files
              are stored on our server.
            </p>

            <p class="text-subtitle-2">Contact option</p>

            <p>
              You can contact us at the email address given in the legal notice
              (impressum). These data voluntarily transmitted to us are only
              stored and used to process the contact. These personal data
              voluntarily transmitted to us will not be transmitted to third
              parties.
            </p>
          </v-card-text>

          <v-card-actions>
            <v-btn text @click="back">
              back
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import router from "../router";

export default {
  methods: {
    back() {
      router.replace("/showContacts").catch(() => {});
    },
  },
  metaInfo: {
    title: "Data privacy statement",
    meta: [
      {
        name: "description",
        content: "Data privacy statement for the web-based crypto messenger",
      },
      {
        name: "robots",
        content: "noindex",
      },
    ],
  },
};
</script>

<style scoped>
.cryptedmail:after {
  content: attr(data-name) "@" attr(data-domain) "." attr(data-tld);
}
</style>
