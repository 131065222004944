<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col md="8" cols="12">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header class="font-weight-bold">
              How does the web-based crypto messenger implement the
              encryption/decryption, the signature and its verificationsprocess?
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p>
                We use ECDH [1] from [2] to derive a shared secret. Then the
                message payload is encrypted with aes [3] from [4]. After that,
                the message is signed with ECDSA [5] from [2] and send to the
                server. The server verifies the signature, stores the encrypted
                messages and forwards it to the recipient. The recipient will
                also verify the signature and decrypt it with the afore
                mentioned proceedings. To get access to your messages the server
                sends you a random alphanumeric string that your clients signs
                and sends back to ensure that you are in possession of the
                private key. Thus no registration process is required. We only
                save the encrypted messages on our server.
              </p>
              [1]:
              <a
                href="https://en.wikipedia.org/wiki/Elliptic-curve_Diffie-Hellman"
                target="_blank"
                >https://en.wikipedia.org/wiki/Elliptic-curve_Diffie-Hellman
              </a>
              <br />
              [2]:
              <a href="https://www.npmjs.com/package/eccrypto" target="_blank"
                >https://www.npmjs.com/package/eccrypto
              </a>
              <br />
              [3]:
              <a
                href="https://de.wikipedia.org/wiki/Advanced_Encryption_Standard"
                target="_blank"
                >https://de.wikipedia.org/wiki/Advanced_Encryption_Standard
              </a>
              <br />
              [4]:
              <a href="https://www.npmjs.com/package/aes256" target="_blank"
                >https://www.npmjs.com/package/aes256
              </a>
              <br />
              [5]:
              <a
                href="https://de.wikipedia.org/wiki/Elliptic_Curve_DSA"
                target="_blank"
                >https://de.wikipedia.org/wiki/Elliptic_Curve_DSA
              </a>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header class="font-weight-bold">
              Where does the encryption/decryption take
              place?</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              The whole encryption/decryption is made on your device in your
              browser. Also the signature and the check of the signature for
              each message is made in your browser. Only the time when the
              message was accepted and saved on our Server is added by the
              server to the message and not part of the signature and
              encryption.</v-expansion-panel-content
            >
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="font-weight-bold">
              Encryption in JavaScript... is this App vulnerable to XSS?
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              Short answer: theoretical yes. The whole encryption is made
              through JavaScript, thus, of course, JavaScript has access to the
              private key. But: We are using vuejs with vuetify and avoid using
              the "v-html"-directive, so in practice it should be difficult to
              inject an XSS-Attack. But keep always in mind, that this is a
              private projekt in beta stage that strives for a tradeoff between
              usability and security. If you find a bug, please report it to the
              mail address given in the legal notice.
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header class="font-weight-bold">
              I get an error when I try to send messages. Why?
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p>
                You may have exceeded your disk limit on the server (100 MB /
                24h). You could delete old messages or wait. You still can
                receive messages if your participant has not reached the limit.
                You also get an error if you logged in twice.
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header class="font-weight-bold">
              How can I delete my messages?
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              You can delete all messages for a specific pubic key with the
              hamburger menu on the top (right side) after you've clicked on the
              contact. When you delete all messages for a specific public key
              the whole conversation is also lost for the recipient.
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header class="font-weight-bold">
              How can I delete my account?
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              There is no account on the server. We only save encrypted messages
              on the server. You can delete all messages for your public/private
              key with the hamburger menu on the top (right side) in MyContacts.
              You are stille able to login, because the server only checks if
              you are in possession of the private key by sending you a random
              alphanumeric string which your client has to sign corrently. You
              also can login with a self generated key pair.
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {};
</script>
