<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col md="8" cols="12">
        <v-btn @click="scanQrButton" class="mr-2 mb-2">Scan QR-Code</v-btn>
        <v-btn
          @click="
            () => {
              uploadQrCode = !uploadQrCode;
              scanQrCode = false;
            }
          "
          class="mb-2"
          >Upload QR-Code</v-btn
        >
        <div v-if="scanQrCode">
          <br />
          <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit" />
          <p class="error">{{ error }}</p>
        </div>
        <div v-if="uploadQrCode">
          <br />
          <qrcode-capture @decode="onDecode" @init="onInit"></qrcode-capture>
        </div>

        <v-form>
          <br />
          Enter public key from receiver

          <v-text-field label="Receiver" v-model="receiverPubKey" type="text" />
          <v-btn :disabled="!derivedSharedSecret" @click="sendMessageToPubKey"
            >Send Message to new Contact</v-btn
          >
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import deriveSharedSecret from "../mixins/deriveSharedSecret.js";
import { QrcodeStream, QrcodeCapture } from "vue-qrcode-reader";

export default {
  mixins: [deriveSharedSecret],
  components: {
    QrcodeStream,
    QrcodeCapture,
  },
  data() {
    return {
      receiverPubKey: "",
      scanQrCode: false,
      uploadQrCode: false,
      error: "",
      camera: "auto",
    };
  },
  watch: {
    receiverPubKey(val) {
      val = val.replace(/\s/g, ""); //remove whitespace
      this.receiverPubKey = val;
      this.deriveSharedSecret(val);
    },
  },
  methods: {
    scanQrButton() {
      this.scanQrCode = !this.scanQrCode;
      if (this.scanQrCode) {
        this.camera = "auto";
      } else {
        this.camera = "off";
      }
      this.uploadQrCode = false;
    },
    sendMessageToPubKey() {
      if (this.receiverPubKey.length > 0)
        this.$router.push({
          name: "showMessages",
          params: { receiverPubKey: this.receiverPubKey },
        });
    },
    onDecode(result) {
      this.receiverPubKey = result;
      this.camera = "off";
      this.scanQrCode = false;
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permisson";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        }
      }
    },
  },
};
</script>
