<template>
  <span>
    {{ decText }}
  </span>
</template>

<script>
import aes256 from "aes256";
export default {
  data() {
    return {
      decText: "",
    };
  },
  async created() {
    this.decText = await aes256.decrypt(this.shared, this.encText);
    this.$emit("decrypted"); //emits event to parent to execute scrollToEnd
  },
  props: ["encText", "shared"],
};
</script>
