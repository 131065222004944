<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col md="8" cols="12">
        <h2>News</h2>
        <v-card outlined>
          <v-card-title>12.03.2022</v-card-title>
          <v-card-text class="text--primary">
            Users can delete individual messages now.
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="back">
              back
            </v-btn>
          </v-card-actions>
        </v-card>
        <br />

        <v-card outlined>
          <v-card-title>12.01.2022</v-card-title>
          <v-card-text class="text--primary">
            File transfer is now possible up to 5 MB. Please report bugs to the
            mail address given in the legal notice.
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="back">
              back
            </v-btn>
          </v-card-actions>
        </v-card>
        <br />
        <v-card outlined>
          <v-card-title>21.10.2021</v-card-title>
          <v-card-text class="text--primary">
            Our first beta version is online for testing. Please report bugs to
            the mail address given in the legal notice.
          </v-card-text>

          <v-card-actions>
            <v-btn text @click="back">
              back
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import router from "../router";

export default {
  methods: {
    back() {
      router.replace("/showContacts").catch(() => {});
    },
  },
  metaInfo: {
    title: "News",
    meta: [
      {
        name: "description",
        content: "News for the Web-Based Crypto Messenger",
      },
    ],
  },
};
</script>
