<template>
  <v-item-group>
    <v-dialog v-model="dialog" persistent max-width="400">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" v-bind="attrs" v-on="on">
          Show public Key
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline">
          Contact Information
        </v-card-title>
        <v-card-text>
          <p>Public key form this contact:</p>
          {{ contact }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-item-group>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      contact: this.$route.params.receiverPubKey,
    };
  },
  async created() {},
  computed: {},
  methods: {},
};
</script>
