
function toBase64String(blob) {
    return new Promise((resolve, reject) => {
      let fileReader = new FileReader();
  
      fileReader.onloadend = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = reject;
      fileReader.readAsDataURL(blob);
    })
  }
  

  //ToDo include in other funtion
  function getMimeTypeFromBlob(blob){

    return new Promise((resolve, reject) => {
        
      let fileReader = new FileReader();

      fileReader.onloadend = () => {
        let arr = (new Uint8Array(fileReader.result)).subarray(0, 4);
        let header = "";
        for(let i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }
        let type = '';
        switch (header) {
            case "89504e47":
              type = "png";
              break;
            case "47494638":
              type = "gif";
              break;
            case "ffd8ffe0":
            case "ffd8ffe1":
            case "ffd8ffe2":
              type = "jpeg";
              break;
            default:
              type = "unknown";
              break;
          }
          resolve(type);
      };
      fileReader.onerror = reject;
      fileReader.readAsArrayBuffer(blob);
    })

  }

  // must call an extra function which returns mimetype
 async function isMimeTypeAllowed(blob) {
    const allowedImageTypes = ['png', 'gif', 'jpeg']
      return allowedImageTypes.includes(await getMimeTypeFromBlob(blob))
    }
  
export { isMimeTypeAllowed, toBase64String, getMimeTypeFromBlob };