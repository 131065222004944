<template>
  <v-item-group>
    <a :href="decFileURL" :download="unzippedDecFileName">
      <v-icon>mdi-paperclip</v-icon>{{ unzippedDecFileName }}
    </a>
  </v-item-group>
</template>

<script>
import aes256 from "aes256";
import JSZip from "jszip";

export default {
  data() {
    return {
      decFileURL: null,
      unzippedDecFileName: null,
      isLoad: false,
    };
  },
  async created() {
    await this.decryptFile();
  },
  methods: {
    loadedFile() {
      this.isLoad = true;
      this.$emit("decrypted");
    },

    async decryptFile() {
      let zip = new JSZip();

      try {
        const decFile = await aes256.decrypt(this.shared, this.encFile); //get base64 zip File

        const unzippedDecFile = await zip.loadAsync(decFile, { base64: true });
        this.unzippedDecFileName = Object.keys(unzippedDecFile.files)[0];
        const unzippedDecFileBlob = await zip
          .file(this.unzippedDecFileName)
          .async("blob");

        this.decFileURL = URL.createObjectURL(unzippedDecFileBlob);
      } catch (e) {
        console.log(e);
      }
      return true;
    },
  },
  props: ["encFile", "shared"],
};
</script>
<style></style>
