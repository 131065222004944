<template>
  <v-item-group>
    <v-dialog v-model="dialog" persistent max-width="400">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="error"
          v-bind="attrs"
          v-on="on"
          :disabled="isEmptyParticipants()"
        >
          Delete Everthing
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline">
          Are you sure?
        </v-card-title>
        <v-card-text
          >All messages of every user will be deleted and the contact
          information (public Key) of every user will not be displayed anymore
          in MyContacts.
          <p class="font-weight-bold">
            If you still want to write to these contacts, please save the public
            keys before continuing.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn color="error" text @click="deleteEverything">
            Delete Everything
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-item-group>
</template>

<script>
import { getAllParticipants } from "../helper/messageHelper";
import eccrypto from "eccrypto";
import crypto from "crypto";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
    };
  },
  async created() {
    this.isEmptyParticipants();
  },
  computed: {
     ...mapGetters(["messages", "bufferedCommunicationPrivateKey"]),
  },
  methods: {
    isEmptyParticipants() {
      return this.getMyParticipants().length === 0;
    },
    getMyParticipants() {
      return getAllParticipants(this.messages);
    },
    async deleteEverything() {
      const receiverPubKey = this.getMyParticipants();

      const deletePayload = {
        receiverPubKey: receiverPubKey,
        date: Date.now(),
      };
      const deletePayloadString = JSON.stringify(deletePayload);
      const deletePayloadStringHash = crypto
        .createHash("sha256")
        .update(deletePayloadString, "utf8")
        .digest();

      //sign data
      const signedDeletePayloadStringHash = await eccrypto
        .sign(this.bufferedCommunicationPrivateKey, deletePayloadStringHash)
        .then(function(sig) {
          return sig;
        })
        .catch(function(e) {
          console.log(e);
        });

      // move in sign or check if sign was successful
      if (signedDeletePayloadStringHash) {
        const data = { deletePayload, signedDeletePayloadStringHash };
        this.$socket.emit("deleteMessages", data);
      }

      this.dialog = false;
    },
  },
};
</script>
